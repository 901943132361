import React from 'react';
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import Card from "react-md/lib/Cards/Card";
import CardTitle from "react-md/lib/Cards/CardTitle";
import CardText from "react-md/lib/Cards/CardText";
import Media from "react-md/lib/Media/Media";
import FontIcon from "react-md/lib/FontIcons/FontIcon";
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../../layout'

const Mission = () => (

  <StaticQuery
    query={graphql`
      query {
        Image: file(relativePath: { eq: "mission.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Grid stacked>
          <Cell
            align='middle'
            desktopSize={8}
            tabletSize={6}
          >
            <Media>
              <Img fluid={data.Image.childImageSharp.fluid} />
            </Media>
          </Cell>
          <Cell
            align='middle'
            desktopSize={8}
            tabletSize={6}
          >
            <Card>
              <CardTitle title="Mission Statement" />
              <CardText>
                <h4 className="md-subheading-2">
                  <FontIcon primary forceSize iconClassName="fa fa fa-arrow-circle-right" />
                  &nbsp;To create a happy, loving and respectful community and adhere
                  to the philosophy and methods of Dr. Maria Montessori.
                </h4>
                <h4 className="md-subheading-2">
                  <FontIcon primary forceSize iconClassName="fa fa fa-arrow-circle-right" />
                  &nbsp;To inspire and encourage children to live mindfully and become responsible, creative and compassionate human beings
                </h4>
              </CardText>
            </Card>
          </Cell>
        </Grid>
      </Layout>
    )}
  />
)

export default Mission
